<div class="layout-wrapper">
  <div class="toolbar">
    <div class="container-fluid">
      <div class="">
        <app-impersonation-status class=""></app-impersonation-status>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-1">
            <div class="flex center position-rel">
              <h3 class="app-name">
                <img src="./../../../assets/img/biotelogo_small.png" alt="" class="linkButton" [routerLink]="['/home']"
                  matTooltip="Home" />
              </h3>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-11 rightNavPanelContainer">
          <div class="row navPanelFirstRow">
            <div class="col-sm-3 col-md-4 col-lg-4">
              <app-procedure-cart-summary></app-procedure-cart-summary>
            </div>
            <div class="col-sm-2 col-md-1 col-lg-2">
              <app-nav-bar></app-nav-bar>

            </div>
            <div class="col-sm-10 col-md-7 col-lg-6">
              <app-nav-clinic-selector></app-nav-clinic-selector>

            </div>
          </div>
          <div class="row navMenuContainer">
            <app-nav-menu menuID=2></app-nav-menu>
          </div>
          <div class="row col-12">
            <app-header-userinfo></app-header-userinfo>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>