import { Component, OnInit, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { ClinicService } from 'src/app/services/graphql/services/clinic.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { indicate } from 'src/app/loader-operator';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('mobileMenu', [
      state('closed', style({
        height: '0',
        overflow: 'hidden',
        opacity: 0
      })),
      state('open', style({
        height: '*'
      })),
      transition('* => *', animate(150))
    ]),
  ]
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  loading$ = new BehaviorSubject<boolean>(false);
  public static clinicId = '1';
  cId = '';
  clinicList = [];
  paginationArgs = {
    filter: {}
  };
  langId = 'en';
  langList = [{ key: 'English', value: 'en' }, { key: 'French', value: 'fr' }]
  scrHeight;
  scrWidth;
  toggleMobileMenu;
  state;
  public isImpersonated : boolean;

  @Output() leftPanel = new EventEmitter();
  constructor(
    public app: AppComponent,
    public auth: AuthService,
    public clinicSvc: ClinicService,
    public shareDataSvc: ShareDataService,
    public translate: TranslateService
  ) {
    this.getScreenSize();
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
  }

  ngAfterViewInit(): void {
    this.getClinics();
  }

  ngOnInit() {
    this.getClinics();
    this.cId = ToolbarComponent.clinicId;
    this.isImpersonated = this.shareDataSvc.isImpersonated;
  }
  showLeftPanel() {
    this.leftPanel.emit();
  }

  langChanged(e) {
    this.langId = e;
    this.translate.use(this.langId);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    setTimeout(() => {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      if (this.scrWidth < 1024) {
        this.state = 'closed';
      } else {
        this.state = 'open';
      }
    }, 0);
  }

  toggleMenu() {
    this.state === 'closed' ? (this.state = 'open') : (this.state = 'closed');
  }

  getClinics() {
    this.clinicSvc.getHeaderClinics(this.paginationArgs).pipe(indicate(this.loading$)).subscribe(clinic => {
      this.clinicList = clinic["nodes"];
    });
  }

}
