import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: '',
        component: LoginComponent,
        outlet: 'loutlet',
      }
    ],
    pathMatch: 'full'
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        component: LoginComponent,
        outlet: 'loutlet',
      }
    ]
  },

  {
    path: '',
    //data: ROUTING_DATA.COMPONENT_DATA,
    loadChildren: () => import('./component/component.module').then(m => m.ComponentModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'cart',
    canActivate: [AuthGuard],
    loadChildren: () => import('./component/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    loadChildren: () => import('./component/report/report.module').then(m => m.ReportModule)
  },
 



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
