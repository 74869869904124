<ng-container *ngFor="let item of menuData">
  <ng-container *ngIf="item.children && item.children.length > 0">
    <ng-container *ngIf="hasPermission(item.moduleName)">
      <button mat-flat-button [matMenuTriggerFor]="menu.childMenu" #trigger="matMenuTrigger"
        class="mat-focus-indicator ng-star-inserted mat-flat-button toolbarButton">
        {{item.title}}
      </button>
    </ng-container>
    <app-nav-menu-sub #menu [menuItems]="item.children" [parentChildMenuModules]="parentChildMenuModules"
      menuID="{{menuID}}"></app-nav-menu-sub>
  </ng-container>
  <ng-container *ngIf="hasPermission(item.moduleName) && item.children.length == 0">
    <button *ngIf="item.LinkHomeSite == menuID" mat-flat-button
      class="mat-focus-indicator mat-menu-trigger ng-star-inserted mat-flat-button toolbarButton"
      [routerLink]=removeDomain(item.link)>{{item.title}}</button>
    <a href={{item.link}} target="_blank"
      *ngIf="item.LinkHomeSite != menuID && item.LinkHomeSite != 0 && (item.newWindow || item.newWindow == undefined)"
      mat-flat-button class="mat-focus-indicatorng-star-inserted mat-flat-button toolbarButton">{{item.title}}</a>
    <a href={{item.link}}
      *ngIf="item.LinkHomeSite != menuID && item.LinkHomeSite != 0 && !item.newWindow && item.newWindow !== undefined"
      mat-flat-button class="mat-focus-indicatorng-star-inserted mat-flat-button toolbarButton">{{item.title}}</a>
  </ng-container>
</ng-container>