import { Input, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CommonPermissionService } from 'src/app/shared/common-permission.service';
import { menuItem } from 'src/app/shared/models/menu';
import { MenuService } from '../../services/menu-service';
import { PermissionDataService } from 'src/app/services/permission-data.service';
import { ShareDataService } from 'src/app/services/share-data.service';



@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  @Input() menuID: number;
  public menuData: menuItem[];
  allChildModules;
  allowedChildModules: string[] = [];
  allowedParentModules: string[] = [];
  parentChildMenuModules: any[] = [];

  constructor(private menuService: MenuService, private commonPermissionService: CommonPermissionService, private permissionDataService: PermissionDataService, private shrdSvc: ShareDataService) {

  }
  Object = Object;

  ngOnInit(): void {
    this.shrdSvc.getCustomerProfile().subscribe(
      (data) => {
        this.getMenu(data);
      }
    );
    
  }

  //get menu data
  getMenu(data) {
    this.menuService.menuQuery({ menusId: {"eq":1} }).subscribe(res => {
      if (res && res.menus) {
        this.menuData = JSON.parse(res.menus.nodes[0].json);
        this.allowedChildModules =data.modules;
        this.parentChildMenuModules = this.permissionDataService.getParentChildMenuModules(this.menuData);      }
    });
  }

  removeDomain(link: string) {
    return link.substr(link.indexOf('/', 9));
  }

  hasPermission(moduleName: string) {
    const moduleNameTrimmed = moduleName.trim();
    return (this.allowedChildModules.includes(moduleNameTrimmed) || moduleNameTrimmed.length === 0) ? true : false;
  }

  hasAllowedChild(parentModuleName: string) {
    return this.allowedParentModules.includes(parentModuleName) ? true : false;
  }

}
