  <mat-form-field appearance="fill" class="no-label-field">
    <input type="text"
           placeholder="select a clinic"
           matInput
           [matAutocomplete]="auto"
           [(ngModel)]="searchInput"
           (input)="Search(searchInput)"
           >
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  >
      <mat-option *ngFor="let clinic of menuitemlist$ | async" [value]="clinic.clinicName" (onSelectionChange)="setClinic(clinic.clinicID)" >  
        {{clinic.clinicName}} - {{clinic.billingSystemId}}
      </mat-option>
    </mat-autocomplete>
    <!-- <mat-error *ngIf="basicInfoFormGroup.controls['account'].invalid">No Clinic found</mat-error> -->
  </mat-form-field>



  <!-- (keyup)="Search(searchString)" -->
  
