import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/app.config.service';
import { AuthService } from 'src/app/auth/auth.service';
import { IPagination, PaginationService } from 'src/app/common/pagination/pagination.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { base_customer_profile, customer_profile } from 'src/app/shared/models/customer-profile';
import { INavSelectorClinicList } from 'src/app/shared/NavSelectorClinicList/INavSelectorClinicList';
import { NavSelectorFactory } from "src/app/shared/NavSelectorClinicList/nav-selector-factory"
import { Auth0Service } from '../../services/auth0.service';
import { ClinicMenuService } from '../../services/clinic-service';
import { clinicsSelect } from '../../shared/models/clinic';
import { menuItem } from '../../shared/models/menu';


@Component({
  selector: 'app-nav-clinic-selector',
  templateUrl: './nav-clinic-selector.component.html',
  styleUrls: ['./nav-clinic-selector.component.scss']
})
export class NavClinicSelectorComponent implements OnInit {
  productForm: any;
  customerprofile: base_customer_profile = new customer_profile;
  sub: any;
  private portal = AppConfig.configurations.portalUrl;

  constructor(private clinicMenuService: ClinicMenuService,
    private auth0service: Auth0Service,
    private shareDataService: ShareDataService, private snackBar: MatSnackBar, public auth: AuthService) { }

  public paginationArgs: IPagination;
  public customerID: number;
  pageService = new PaginationService();
  public clinicMenuList: menuItem[];
  public customerClinicID: number;
  public customerClinicName: string;
  selectedValue: number;
  menuitemlist$: Observable<clinicsSelect>;
  Defaultmenuitemlist$: Observable<clinicsSelect>;
  searchStringlast: string;
  searchInput: string;
  clinicList: INavSelectorClinicList;


  ngOnInit(): void {

    this.sub = this.shareDataService.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.customerprofile = data;
          this.customerID = this.customerprofile.customerId;

          if (data.user_metadata || data.clinicId) {
            this.setOnInitValues();
          } else {
            this.getDefaultClinicId();
          }
        }
      }
    );
  }

  getDefaultClinicId() {
    this.clinicMenuService.clinicQuery({ filter: { customerID: {"eq" :Number(this.customerprofile.customerId)} }, first: 30, orderBy: { clinicName: 'ASC' } }).pipe().subscribe(clinic => {
      if (clinic.length > 0) {
        this.customerprofile.clinicId = clinic[0]?.clinicID;
        this.setOnInitValues();
      } else {

        if (this.shareDataService.isImpersonated) {
          this.snackBar.open('Clinics not found for this user. Redirecting to Admin Portal.', 'X', { panelClass: ['error'], duration: 5000 });
          setTimeout(() => {
            window.location.href = this.portal.adminPortalUrl;
          }, 5000);
        } else {
          this.snackBar.open('No Clinics are mapped. Please contact Customer Service.', 'X', { panelClass: ['error'], duration: 5000 });
          setTimeout(() => {
            this.auth.logout();
          }, 5000);
        }

      }
    });
  }

  setOnInitValues() {
    this.customerClinicID = this.customerprofile.clinicId;
    if (this.customerClinicID == null) {
      this.auth0service.getUsersClinic(this.customerID).subscribe(
        data => {
          this.SetMenuDefaultValue(this.customerprofile.clinicId);
        });
    }
    else {
      this.SetMenuDefaultValue(this.customerprofile.clinicId);
    }
    this.selectedValue = this.customerprofile.clinicId;
    this.clinicList = NavSelectorFactory.getClinicByPermission(this.customerprofile.permissions);
    this.menuitemlist$ = this.clinicList.getClinic(this.customerID);
    this.searchInput = 'Loading...';
  }

  SetMenuDefaultValue(clinicId: number): void {
    if (clinicId == null || clinicId == 0) {
      this.clinicList.getClinic(this.customerID).subscribe(data => {
        this.UpdateTextDisplaid(data);
        this.setClinic(data[0]?.clinicID);
      });
    } else {
      //get the clinic data to put int he drop down
      this.clinicMenuService.clinicQuery({ filter: { clinicID: {"eq" :clinicId} }, first: 1 }).subscribe(data => { this.UpdateTextDisplaid(data) });
    }
  }

  UpdateTextDisplaid(data): void {
    this.searchInput = data[0].clinicName + ' - ' + data[0].billingSystemId;
  }

  Search(testString: string): boolean {
    if (testString ? testString.length : 0 > 2 && testString !== this.searchStringlast) {
      this.menuitemlist$ = this.clinicList.searchClinic(this.customerID, testString);
      this.searchStringlast = testString;
    }
    return true;
  }

  setClinic(clinicID: string): boolean {
    this.customerprofile.clinicId = parseInt(clinicID);
    this.shareDataService.setCustomerProfile(this.customerprofile);
    this.customerClinicID = parseInt(clinicID);
    this.auth0service.setUserClinic(this.customerID, parseInt(clinicID, 10));
    return true;
  }

  selectAllText($event: any): void {
    $event.target.select();
  }

}

