<div class="login toolbar-left" *ngIf="profile">

    <button class="d-none d-xl-block menu-welcome" mat-button color="primary" [matMenuTriggerFor]="menu">Welcome, {{ profile.name
      }}
      <mat-icon><i class="fa fa-caret-down" aria-hidden="true"></i></mat-icon>
    </button>
    <button class="d-xl-none" mat-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon><i class="fa fa-2x fa-user-circle-o" aria-hidden="true"></i></mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="profile-menu">
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
