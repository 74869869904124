import gql from 'graphql-tag';
import { CLINIC_ATTRIBUTES, PRIMARY_ADDRESS, CLINIC_GROUPS } from '../fragment/clinic-fragment';

export const clinicHeader = gql`
query{
  clinics{
    totalCount
    nodes{
      id
      clinicBusinessName
      acceptHMO
      acceptInsurance
      acceptPPO
      active
      allowCheckMoneyOrder
    }
  }
}`;

export const clinics = gql`
query clinics($first:Int, $last:Int, $after: String, $before:String, $where: ClinicFilterInput, $order: ClinicSort){
  clinics(first:$first, last:$last, after:$after, before:$before, where: $where, order: $order) {
    pageInfo{
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges{
      cursor
      node{
        ...clinicsAttr
        ...primaryAddressAttr
        ...clinicGroupsAttr
      }
    }
  }
}
${CLINIC_ATTRIBUTES}
${PRIMARY_ADDRESS}
${CLINIC_GROUPS}
`;

export const clinicInfo = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        id
        clinicName
        clinicDesc
        message
        deaNumber
        clinicTaxId
        billingSystemId
        invoiceName
        effectiveDate
        billingEmailRecipients
        ordersEmailRecipients
        reportsEmailRecipients
        criticalInfoEmailRecipients
        deaFirstName
        deaLastName
        marketingEmailRecipients
        clinicBusinessName
        clinicLegacyStatus
      }
  }
}`;

export const clinicCheckDtp = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        isEnableDTP
      }
  }
}`;
export const getCustomersClinics = gql`
query clinics(
  $first: Int
  $last: Int
  $after: String
  $before: String
  $where: ClinicCustomerMappingByPermissionFilterInput
  $order: [ClinicCustomerMappingByPermissionSort!]  
) {
    clinicCustomerMappingByPermission(
    first: $first
    last: $last
    after: $after
    before: $before
    where: $where
    order: $order
  ) {
    nodes{
          	clinicID
          	clinicName
          	nonPelleting
          	nutraceuticalEnabled
          	selfReportingProcedures
        }
    
  }
}
`;

export const clinicAddress = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        billingSystemId
        clinicName
        ClinicAddresses{
          address{
            phoneNumber
            address1
            address2
            city
            zipPostalCode
            stateProvince{
              name
            }
            isPrimary
          }
        }
      }
  }
}`;

export const clinicCheckEnrolledInAutoPay = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        enrollAutoPay
      }
  }
}`;