<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let child of menuItems">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
        <span>{{child.title}}</span>
      </button>
      <app-nav-menu-sub #menu [menuItems]="child.children" menuID={{menuID}}></app-nav-menu-sub>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children || child.children.length === 0">
      <span *ngIf="hasPermission(child.moduleName)">
        <button mat-menu-item [routerLink]="removeDomain(child.link)"
          *ngIf="child.LinkHomeSite == menuID || child.LinkHomeSite == 0 ">{{child.title}}</button>
      </span>
      <a href={{child.link}} target="_blank" *ngIf="child.LinkHomeSite != menuID && child.LinkHomeSite != 0 && (child.newWindow || child.newWindow == undefined)" mat-menu-item><span>{{child.title}}</span></a>
      <a href={{child.link}} *ngIf="child.LinkHomeSite != menuID && child.LinkHomeSite != 0 && !child.newWindow && child.newWindow !== undefined" mat-menu-item><span>{{child.title}}</span></a>
    </span>
  </ng-container>
</mat-menu>