import { Injectable } from '@angular/core';
import { CommonPermissionService } from '../shared/common-permission.service';
import { base_customer_profile, customer_profile } from '../shared/models/customer-profile';
import { menuItem } from '../shared/models/menu';
import { ShareDataService } from './share-data.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionDataService {
  customerprofile : base_customer_profile= new customer_profile;
  sub: any;
constructor(private commonPermissionService: CommonPermissionService,private data: ShareDataService) { 
  this.sub= this.data.getCustomerProfile().subscribe(
    (data) => {
      if(data){
        this.customerprofile = data;
      }
    }
  );
}
getParentChildMenuModules(menuData: menuItem[]) {
  const arr = [];
  menuData.map(
    (ele: menuItem) => {
      const obj = {} as {parentMenu: string, childMenu: string[]};
      obj.parentMenu = ele.moduleName;
      let childMenus = [];
      ele.children.map(
        (item) => {
          if(item.moduleName) {
            childMenus.push(item.moduleName.trim());
          }
        }
      );
      obj.childMenu = childMenus;
      arr.push(obj);
    }
  );
  const parentChildMenuModules = arr.filter(
    (item: any) => {
      return item.childMenu.length > 0;
    }
  );
  return parentChildMenuModules;
}

setPermissionForMenu(parentChildMenuModules) {
  if(parentChildMenuModules) {
    const allowedParentModules = [];
  if (this.customerprofile.permissions) {
    const allowedChildModules = this.commonPermissionService.getModule();
    if(allowedChildModules) {
      allowedChildModules.map(
        (item: string) => {
          
            parentChildMenuModules.map(
              (ele:any) => {
                if(ele.childMenu.includes(item)) {
                  allowedParentModules.push(ele.parentMenu);
                }
              }
            );
          
        }
      );
      return [...new Set(allowedParentModules)];
    }
  }
  }
  
}

hasPermission(moduleName: string, allowedChildModules) {
  const trimmedName = moduleName.trim();
  return allowedChildModules.includes(trimmedName) ? true : false;
}

}
